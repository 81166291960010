import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sentryService from 'Services/sentryService'
import Lazyload from 'Components/Lazyload'
import TopNav from './TopNav'

const MAX_CONTAINER_WIDTH = 1250

const SideNavDrawer = Lazyload(() => import('./SideNavDrawer'))
// sidenav loading placeholder is same width to avoid flicker
const Footer = Lazyload(() => import('./Footer'))
const ApplicationError = Lazyload(() => import('Components/ApplicationError'))

// on height and scroll changes of view component
// test 3 things
// if long text pages and footer are proper
// if dynamic long text pages and scroll are proper
// if no content pages have sufficent height for footer

class ViewCore extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileOpen: false,
      errored: false,
    }
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this)
    this.closeMobileSidenav = this.closeMobileSidenav.bind(this)
  }

  static getDerivedStateFromError(error) {
    // eslint-disable-next-line no-console
    console.log('viewcore error: ', error)
    sentryService.captureException(error)
    // Update state so the next render will show the fallback UI.
    return { errored: true }
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    sentryService.captureException(error)
    // eslint-disable-next-line no-console
    if (process.env.DEBUG) console.log('caught viewcore error: ', error, info)
  }

  handleDrawerToggle() {
    this.setState((prevState) => ({ mobileOpen: !prevState.mobileOpen }))
  }

  closeMobileSidenav() {
    this.setState({ mobileOpen: false })
  }

  render() {
    const {
      isFooterPresent,
      sidebarVariant,
      children,
      pageProps,
      topNavPresent,
      fullWidth,
    } = this.props

    const { mobileOpen, errored } = this.state

    const sidebarPresent =
      sidebarVariant !== 'NONE' && sidebarVariant !== 'LANDING'
    // used to control width of content area for non mobile screens

    return (
      <div id="ylurnPageContainer" className="bg-white">
        {/* removing explicit height so that on edge cases top nav expands */}
        {topNavPresent && (
          <div className="bg-gray-50 md:right-0 w-full shadow-md fixed z-30">
            <TopNav
              handleDrawerToggle={this.handleDrawerToggle}
              sidebarVariant={sidebarVariant}
              pageProps={pageProps}
            />
          </div>
        )}
        <main>
          {topNavPresent && (
            <div
              className="h-12 sm:h-14 md:h-16" /* height pattern copied from mui toolbar styles */
            />
          )}
          <div>
            {errored && <ApplicationError />}
            {!errored && (
              <div
                style={{
                  maxWidth: fullWidth ? null : `${MAX_CONTAINER_WIDTH}px`,
                  padding: 0,
                  margin: '0 auto',
                }}
              >
                <div>
                  <div className="flex flex-1">
                    <div>
                      {/* hidden part added to avoid flicker during ssr */}
                      <div className="hidden sm:block">
                        {sidebarPresent && (
                          <div className="w-screen max-w-xs" />
                        )}
                      </div>
                      <SideNavDrawer
                        sidebarVariant={sidebarVariant}
                        mobileOpen={mobileOpen}
                        handleDrawerToggle={this.handleDrawerToggle}
                        pageProps={pageProps}
                        closeMobileSidenav={this.closeMobileSidenav}
                      />
                    </div>
                    <div
                      style={{
                        minHeight:
                          'calc(100vh - 64px)' /*  otherwise footer will be brought up on small contents */,
                      }}
                      className={`flex-1 p-0 ${
                        fullWidth ? 'p-0' : 'px-4 pt-3 pb-0'
                      }`}
                    >
                      {children}
                    </div>
                  </div>
                  {/* footer is always full width - check footer comp styles */}
                  {isFooterPresent && <Footer />}
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    )
  }
}

ViewCore.propTypes = {
  sidebarVariant: PropTypes.string,
  isFooterPresent: PropTypes.bool.isRequired,
  topNavPresent: PropTypes.bool.isRequired,
  pageProps: PropTypes.object,
  fullWidth: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

ViewCore.defaultProps = {
  sidebarVariant: null,
  pageProps: null,
}

export default ViewCore
