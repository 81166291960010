import React, { useContext } from 'react'
import Head from 'next/head'
import GlobalsContext from 'Contexts/GlobalsContext'

const BeamerButton = () => {
  const globals = useContext(GlobalsContext)
  const {
    cookies: { loggedUser },
  } = globals
  return (
    <div>
      <Head>
        <script
          dangerouslySetInnerHTML={{
            __html: `var beamer_config = {
                  product_id : 'cmwDZVCe26473',
                  selector : '.beamerTrigger',
                  //display : 'right', /*Optional: Choose how to display the Beamer panel in your site*/
                  //top: 0, /*Optional: Top position offset for the notification bubble*/
                  //right: 0, /*Optional: Right position offset for the notification bubble*/
                  //bottom: 0, /*Optional: Bottom position offset for the notification bubble*/
                  //left: 0, /*Optional: Left position offset for the notification bubble*/
                  //button_position: 'bottom-right', /*Optional: Position for the notification button that shows up when the selector parameter is not set*/
                  //icon: 'bell_lines', /*Optional: Alternative icon to display in the notification button*/
                  //language: 'EN', /*Optional: Bring news in the language of choice*/
                  //filter: 'admin', /*Optional : Bring the news for a certain role as well as all the public news*/
                  //lazy: false, /*Optional : true if you want to manually start the script by calling Beamer.init()*/
                  //alert : true, /*Optional : false if you don't want to initialize the selector*/
                  //delay : 0, /*Optional : Delay (in milliseconds) before initializing Beamer*/
                  //embed : false, /*Optional : true if you want to embed and display the feed inside the element selected by the 'selector' parameter*/
                  //mobile : true, /*Optional : false if you don't want to initialize Beamer on mobile devices*/
                  //notification_prompt : 'sidebar', /*Optional : override the method selected to prompt users for permission to receive web push notifications*/
                  //onclick : your_onclick_function(url, openInNewWindow), /*Optional : Beamer will call this function when a user clicks on a link in one of your posts*/
                  //onopen : your_onopen_function, /*Optional : Beamer will call this function when opening the panel*/
                  //onclose : your_onclose_function, /*Optional : Beamer will call this function when closing the panel*/
                  user_firstname : "${loggedUser ? loggedUser.name : ''}",
                  //user_lastname : "lastname", /*Optional : Input your user lastname for better statistics*/
                  user_email : "${loggedUser ? loggedUser.email : ''}",
                  user_id : "${loggedUser ? loggedUser.id : ''}",
                };`,
          }}
        />
        <script
          type="text/javascript"
          src="https://app.getbeamer.com/js/beamer-embed.js"
          defer="defer"
        />
      </Head>
      <span
        // pr5 for badge to render properly
        className="text-base cursor-pointer pr-5 beamerTrigger animate-bounce text-red-600 font-semibold pl-2 md:pl-0"
      >
        WHAT&apos;S NEW?
      </span>
    </div>
  )
}

export default BeamerButton
