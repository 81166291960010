import React from 'react'
import PropTypes from 'prop-types'
import ViewCore from './ViewCore'

const Layout = (WrappedComponent, options) => {
  const {
    sidebarVariant,
    isFooterPresent,
    topNavPresent = true,
    fullWidth = false,
  } = options
  function HOC(props) {
    // props important, for passing resolves to child component
    return (
      <ViewCore
        isFooterPresent={isFooterPresent}
        sidebarVariant={sidebarVariant}
        topNavPresent={topNavPresent}
        fullWidth={fullWidth}
        pageProps={props}
      >
        <WrappedComponent {...props} />
      </ViewCore>
    )
  }
  return HOC
}

Layout.propTypes = {
  sidebarVariant: PropTypes.oneOf(['HOME', 'COURSE', 'NONE']).isRequired,
  isFooterPresent: PropTypes.bool,
}

export default Layout
