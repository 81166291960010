import React from 'react'
import Lazyload from 'react-lazyload'
import PropTypes from 'prop-types'

const LazyloadOnScroll = ({ children, ...extraProps }) => (
  <Lazyload {...extraProps}>{children}</Lazyload>
)

LazyloadOnScroll.propTypes = {
  children: PropTypes.node.isRequired,
  // takes placeholder prop as node
}

export default LazyloadOnScroll
