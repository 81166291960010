import React from 'react'
import PropTypes from 'prop-types'

const IconButton = ({ children, className, ...extraProps }) => (
  <button
    type="button"
    className={`p-2 hover:bg-gray-100 rounded-full outline-none flex items-center justify-content ${className}`}
    {...extraProps}
  >
    {children}
  </button>
)

IconButton.defaultProps = {
  className: '',
}

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default IconButton
