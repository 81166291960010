import React from 'react'
import Lazyload from 'Components/Lazyload'
import BeamerButton from 'Components/BeamerButton'

const AuthStatusButton = Lazyload(() =>
  import('Components/Layout/AuthStatusButton')
)

const TopNavElements = [
  {
    value: 'demo',
    label: 'REQUEST A DEMO',
    href: '/?modal=contactUs',
  },
  {
    value: 'pricing',
    label: 'PRICING',
    href: '/pricing',
  },
  /*
  will be uncommented when blog UI is ready
  {
    value: 'blog',
    label: 'BLOG',
    href: '/blog',
  },
  */
  {
    value: 'newReleases',
    label: <BeamerButton />,
    href: '/',
  },
  {
    value: 'login',
    label: <AuthStatusButton />,
  },
]

export default TopNavElements
