import React, { useState } from 'react'
import PropTypes from 'prop-types'
import LazyloadOnScroll from 'Components/LazyloadOnScroll'

// use base64 image for fallback to avoid loop if fallback image fails
const fallbackImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAoUlEQVR42u3RAQ0AMAgAoJv8VUxgBqNZwzmoQGT1f6wRQoQgRAhChCBECEKECBGCECEIEYIQIQgRghCECEGIEIQIQYgQhCBECEKEIEQIQoQgBCFCECIEIUIQIgQhCBGCECEIEYIQIQhBiBCECEGIEIQIQQhChCBECEKEIEQIQhAiBCFCECIEIUIQghAhCBGCECEIEYIQIUKEIEQIQoQg5LoBSO3/eScJzxYAAAAASUVORK5CYII='

const ImageBlock = ({
  lazyloadProps,
  src,
  height,
  className,
  alt,
  ...otherProps
}) => {
  const [currentSrc, setSrc] = useState(src)

  const onError = () => setSrc(fallbackImage)

  return (
    <LazyloadOnScroll height={height} {...lazyloadProps}>
      <img
        alt={alt}
        src={currentSrc}
        height={height}
        className={className}
        onError={onError}
        {...otherProps}
      />
    </LazyloadOnScroll>
  )
}

ImageBlock.defaultProps = {
  lazyloadProps: {},
  alt: '',
  className: '',
}

ImageBlock.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  lazyloadProps: PropTypes.object,
}

export default ImageBlock
