import React from 'react'
import IconButton from 'Components/IconButton'
import MenuIcon from 'Svgs/Menu'
import PropTypes from 'prop-types'
import Link from 'next/link'
import TopNavElements from 'Constants/TopNavElements'
import ImageBlock from 'Components/ImageBlock'
import TopNavElement from './TopNavElement'

const MAX_CONTAINER_WIDTH = 1250

const TopNav = ({ sidebarVariant, handleDrawerToggle }) => {
  return (
    <div>
      {/* div wrapper required for max width to work */}
      <div
        className="mx-auto bg-transparent h-full flex relative items-center px-3 md:px-6 py-1 md:py-2"
        style={{ maxWidth: `${MAX_CONTAINER_WIDTH}px` }}
      >
        {((sidebarVariant !== 'NONE' && sidebarVariant !== 'LANDING') || // normal sidenavs
          sidebarVariant === 'LANDING') && (
          <IconButton
            color="primary"
            aria-label="Open drawer"
            onClick={handleDrawerToggle}
            className="outline-none py-3 px-1 mr-2 md:hidden"
          >
            <MenuIcon className="h-6" />
          </IconButton>
        )}
        <Link href="/">
          <a className="pointer-cursor" style={{ marginTop: '5px' }}>
            <ImageBlock
              alt="Site logo"
              className="h-10 w-auto"
              src="/images/ylurn-transparent-xs.png"
              height={35}
            />
          </a>
        </Link>
        <div className="flex-1">
          <div className="hidden md:block">
            <div className="tracking-widest flex text-base items-center justify-end">
              {/* custom top nav will be an array of { label, href } */}
              {TopNavElements.map((item) => (
                <TopNavElement key={item.value} data={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

TopNav.propTypes = {
  sidebarVariant: PropTypes.oneOf(['COURSE', 'HOME', 'NONE', 'LANDING'])
    .isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
}

export default TopNav
