import React from 'react'
import Link from 'next/link'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'

const TopNavElement = ({ data, router }) => (
  <div
    className={`m-4 md:my-0 md:mx-4 ${router.pathname === data.href ? '' : ''}`}
  >
    <div>
      {typeof data.label === 'string' ? (
        <Link href={data.href}>
          <a className="cursor-pointer h-full w-full text-base font-bold text-yellow-700 tracking-widest hover:opacity-80 ml-2 sm:ml-0">
            {data.label}
          </a>
        </Link>
      ) : (
        <div>{data.label}</div>
      )}
    </div>
  </div>
)

TopNavElement.propTypes = {
  router: PropTypes.object.isRequired,
  data: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    href: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
}

export default withRouter(TopNavElement)
